export async function handleTwoFa(status, router, apiRepository, email, password) {
    if (status === 'twofa_required') {
        router.push({ name: 'twofa-challenge' });
    }

    if (status === 'twofa_forced') {
        const response = await apiRepository.enableTwoFa({ email: email , password: password });

        if (response?.data?.error) {
            throw response.data;
        }

        // need to be enabled then push to this
        router.push({ name: 'twofa-auth' });
    }
}
