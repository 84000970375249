<template>
    <v-layout
        mt-5
        justify-center>
        <v-flex
            xs12
            sm10
            md8
            lg5
            pt-5
            mt-5>
            <v-card
                light
                class="wrap-content gray-icons">
                <v-alert
                    :value="alertMessage"
                    :type="alertType"
                    transition="fade-transition"
                    class="mt-4 mx-4">
                    <span v-html="alertMessage" />
                </v-alert>

                <template v-if="tokenValidationLoading">
                    <loader />
                </template>

                <template v-else-if="tokenIsValid && !tokenValidationLoading">
                    <v-toolbar
                        dark
                        color="white"
                        class="first-line-toolbar">
                        <v-toolbar-title
                            class="light-blue--text title-block title">
                            Create your new password
                        </v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <v-container
                            fluid
                            grid-list-md>
                            <v-form
                                id="password-reset-form"
                                v-model="formIsValid"
                                class="text-xs-center"
                                @submit.prevent="submit">
                                <p class="v-input py-2 px-5">
                                    Almost there! Create your new password below. Make it strong, like a secret code for your superhero identity.
                                </p>

                                <v-text-field
                                    v-model="password"
                                    label="New Password"
                                    :type="showPassword ? 'text' : 'password'"
                                    :rules="passwordRules"
                                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                    required
                                    class="pt-2 px-5"
                                    @click:append="showPassword = !showPassword" />
                                <small
                                    class="py-2 px-5 d-block"
                                    style="text-align: left">
                                    <p class="mb-0">Must be at least 8 or more characters.</p>
                                    <p class="mb-0">Must contain at least one uppercase and one lowercase letter.</p>
                                    <p class="mb-0">Must contain at least one symbol.</p>
                                    <p class="mb-0">Must contain at least one number</p>
                                </small>
                                <v-text-field
                                    v-model="confirmPassword"
                                    label="Confirm Password"
                                    :type="showPassword ? 'text' : 'password'"
                                    :rules="passwordRules"
                                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                    required
                                    class="py-4 px-5"
                                    @click:append="showPassword = !showPassword" />

                                <div class="form-button">
                                    <v-btn
                                        flat
                                        large
                                        type="submit"
                                        form="password-reset-form"
                                        class="blue-btn"
                                        color="white"
                                        :loading="passwordResetLoading"
                                        :disabled="!formIsValid || confirmPassword !== password">
                                        Reset Password
                                    </v-btn>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </template>

                <template v-else-if="!tokenIsValid && !tokenValidationLoading">
                    <div class="pt-5 px-5 pb-3 text-md-center">
                        <h2>Error: This password reset link has expired!</h2>
                        <p>
                            Please request a new password reset link <router-link to="/forgot-password">
                                here
                            </router-link>.
                        </p>
                    </div>
                </template>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import Loader from '../globals/Loader.vue';
import ApiRepository from '../../api-repository';
import { sleep } from '../../helpers';
import { handleTwoFa } from '@/helpers/handleTwoFa';
import recaptcha from '@/mixins/recaptcha';

export default {
    components: {
        Loader
    },
    mixins: [recaptcha],
    title: 'Password Reset',
    data() {
        return {
            alertType: 'error',
            alertMessage: null,
            formIsValid: false,
            passwordResetLoading: false,
            confirmPassword: '',
            showPassword: false,
            password: '',
            token: null,
            tokenValidationLoading: true,
            tokenIsValid: false,
            passwordRules: [
                (v) => (v.length >= 8) || 'Password must be 8 or more characters',
            ],
            enforcedReset: false,
        };
    },
    async created() {

        this.$apiRepository = new ApiRepository();

        // Validate the token before continuing
        try {
            // Grab the data we need from the URL (passed from the server in the reset email)
            this.email = this.$route.query.email;
            this.userId = this.$route.query.user_id;
            this.token = this.$route.query.token;
            this.enforcedReset = this.$route.query.enforcedReset;

            const response = await this.$apiRepository.getIsPasswordResetValid(this.userId, this.token);

            // Only trust the token if the result is true
            if (response.data.result) {
                this.tokenIsValid = true;
            }
            // From here on, the page considers the token invalid by default
            // so the user will see the error message once the loader is hidden
        } catch (error) {
            console.log('Error retrieving token validation:', error);
        } finally {
            // Always stop loading when once async ops are done
            this.tokenValidationLoading = false;
        }
    },
    methods: {
        showAlert(type, message) {
            this.alertType = type;
            this.alertMessage = message;
        },
        clearAlert() {
            this.alertMessage = null;
        },
        async submit() {

            const { email, password } = this;

            try {
                this.clearAlert();
                this.passwordResetLoading = true;

                await this.executeRecaptcha('reset');

                await this.$apiRepository.resetPassword(
                    this.userId,
                    this.token,
                    password,
                    'send',
                    this.recaptchaToken,
                    this.enforcedReset
                );

                this.showAlert('success', 'You have successfully reset your password. Automatically logging you in now...');

                // Sleep for a second so the user can read the message
                await sleep(1000);

                const result = await this.$store.dispatch('login', {
                    email,
                    password,
                    init: true,
                    recaptchaToken: this.recaptchaToken,
                    recaptchaAction: 'reset'
                });

                await handleTwoFa(result.status, this.$router, this.$apiRepository, email, password);
            } catch(error) {
                this.showAlert('error', error.response.data.errors.password[0]);
            } finally {
                // Always disable the loader when complete
                this.passwordResetLoading = false;
            }
        },
    }
};
</script>
