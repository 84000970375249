import { sleep } from '@/helpers';
import { loadRecaptchaScript } from '@/helpers/manageRecaptcha';

export default {
    data() {
        return {
            recaptchaToken: '',
            recaptchaVerified: false
        };
    },
    mounted() {
        loadRecaptchaScript();
    },
    methods: {
        async executeRecaptcha(action) {
            await sleep(100);
            const token = await window.grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action });
            this.recaptchaToken = token;
            this.recaptchaVerified = true;
        }
    }
};
